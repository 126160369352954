<template>
  <div>
    <h3>
      <span v-if="queuePosition === null">
        You are in a queue.
      </span>
      <span v-else>
        You are position {{queuePosition}} in the queue.
      </span>
      <br/><br/>  
      Your queue position is automatically checked every few seconds and you will be able to buy tickets soon.
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <Button class="mt-10" :loading="!this.queueCheck">Checking Queue</Button>
        </v-col>
      </v-row>
    </h3>
  </div>
</template>
  
<script>
  import axios from "axios"
  export default {
    components: {
      Button: () => import('./Button'),
    },  

    data () {
      return {
        queuePosition: null,
        queueNumber: 0,
        queueCheck: true,
      }
    },
    
    async created() {
      let osAPi = "https://api2.openstage.live/v1/"
//      let osAPi = "http://localhost:5001/v1/"
      if (this.$route.params.boxOfficePath == "SamRyder" || this.$route.params.boxOfficePath == "OnlyThePoets") {
        this.queued = true
        this.queueNumber = this.$cookies.get("queueNumber");
        if (!this.queueNumber) {
          try {
            let response = await axios.post(osAPi + "ticketQueueNumber");
            var json = response.data;
            this.queueNumber = json.number
            this.$cookies.set("queueNumber",this.queueNumber, 60 * 60);
          } catch (error) {
            console.log("could not get queue number", error)
            return
          }
        }
        while (this.queued) {
          this.queueCheck = true
          try {
            let response = await axios.post(osAPi + "ticketQueueState");
            var json = response.data;
            if (this.queueNumber < json.position) {
              this.queued = false
            } else {
              this.queuePosition = this.queueNumber - json.position
            }
          } catch (error) {
            console.log("could not get queue number position", error)
          }
          this.queueCheck = false
          if (this.queued) await new Promise(r => setTimeout(r, 5000));
        }
      } 
      this.$emit('queued', this.queued);
    },
    
  }
</script>

<style lang="sass" scoped>
  .v-btn
    color: var(--text-color)
</style>

